import React from "react"
import { graphql } from "gatsby"

// import app components
import PageWrapper from "../../../../components/pageWrapper"
import { Single } from "../../../../features/jobs"
import Seo from "../../../../components/seo"

export const Head = props => {
  return (
    <Seo
      pageContext={{
        seo: {
          title: props.data.lever.text,
          metaDesc: props.data.lever.descriptionPlain,
        },
      }}
    />
  )
}

const Template = props => {
  const {
    data: { lever },
  } = props

  return (
    <PageWrapper {...props}>
      <Single {...lever} />
    </PageWrapper>
  )
}

export const Query = graphql`
  query PageJob($id: String!) {
    lever(id: { eq: $id }) {
      additional
      additionalPlain
      applyUrl
      categories {
        commitment
        level
        location
        team
      }
      salaryRange {
        min
        max
        currency
        interval
      }
      salaryDescription
      salaryDescriptionPlain
      createdAt
      description
      descriptionPlain
      hostedUrl
      id
      lever_id
      lists {
        content
        text
      }
      text
    }
  }
`

export default Template
