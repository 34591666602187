import React from "react"
import {
  Box,
  Container,
  Grid,
  Typography,
  Stack,
  Skeleton,
} from "@mui/material"
import parse from "html-react-parser"

// import app components
import { Button } from "../../../submodules/components/button"
import { Video } from "../../../submodules/components/video"
import { HEADER_HEIGHT } from "../../../../constants"

const Hero = props => {
  const { headline, subline, buttons, video } = props

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        py: 5,
        minHeight: `calc(100vh - ${HEADER_HEIGHT * 2}px)`,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={6} sx={{ alignItems: "center" }}>
          <Grid item xs={12} lg={5}>
            <Stack spacing={4}>
              <Stack spacing={2}>
                {headline && (
                  <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                      fontSize: { xs: "2.4rem", md: "2.6rem", xl: "3.2rem" },
                      lineHeight: 1.2,
                    }}
                  >
                    {parse(headline)}
                  </Typography>
                )}

                {subline && (
                  <Typography variant="h3" sx={{ fontWeight: 400 }}>
                    {parse(subline)}
                  </Typography>
                )}
              </Stack>

              {buttons && (
                <Stack direction="row" spacing={2} sx={{ pt: 2 }}>
                  {buttons.map((o, index: number) => (
                    <Button key={index} {...o.link} variant={o.variant} />
                  ))}
                </Stack>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Box
              sx={{
                position: "relative",
                borderRadius: "1rem",
                overflow: "hidden",
                width: "100%",
              }}
            >
              <Video {...video} backgroundVideo={1} />

              <Skeleton
                animation="wave"
                width="100%"
                height="100%"
                sx={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  transform: "none",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Hero
