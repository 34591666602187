import React from "react"
import { Stack, IconButton } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"
import parse from "html-react-parser"

// import app components
import type { SocialType } from "./types"

type Props = {
  items: SocialType[]
}

const Socials = (props: Props) => {
  const { items } = props

  return (
    <Stack
      direction="row"
      spacing={3}
      sx={{ width: { xs: "100%", md: "auto" } }}
    >
      {items &&
        items
          .filter(item => item?.icon?.svg && item?.url)
          .map((item, index) => {
            return (
              <a
                key={index}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={`Visit ${item.icon.title}`}
              >
                <IconButton
                  size="small"
                  aria-label={`${item.icon.title} social link`}
                  sx={{
                    svg: {
                      width: "20px",
                      height: "20px",
                      path: {
                        fill: colors.icons[700],
                      },
                    },
                  }}
                >
                  {parse(item.icon.svg)}
                </IconButton>
              </a>
            )
          })}
    </Stack>
  )
}

export default Socials
