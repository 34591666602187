import React from "react"
import { Box, Grid, Typography, Container, Stack } from "@mui/material"
import parse from "html-react-parser"

// import app components
import { Image } from "../../../submodules/components/image"

const Columns = props => {
  const { subline, headline, text, columnsdesktop, columnsmobile, items } =
    props

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container maxWidth="lg">
        {(subline || headline || text) && (
          <Stack spacing={2} sx={{ mb: 6, alignItems: "center" }}>
            {subline && (
              <Typography variant="subtitle2" color="primary" component="div">
                {parse(subline)}
              </Typography>
            )}

            {headline && (
              <Typography variant="h2">{parse(headline)}</Typography>
            )}

            {text && <Typography variant="body2">{parse(text)}</Typography>}
          </Stack>
        )}

        <Grid container spacing={6} sx={{ justifyContent: "center" }}>
          {items &&
            items.map((o, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12 / columnsmobile}
                  md={12 / Math.ceil((columnsmobile + columnsdesktop) / 2)}
                  lg={12 / columnsdesktop}
                >
                  <Stack spacing={2} sx={{ px: 1 }}>
                    {o.image && <Image {...o.image} />}

                    {o.headline && (
                      <Typography variant="h3">{parse(o.headline)}</Typography>
                    )}

                    {o.text && (
                      <Typography variant="body2">{parse(o.text)}</Typography>
                    )}
                  </Stack>
                </Grid>
              )
            })}
        </Grid>
      </Container>
    </Box>
  )
}

export default Columns
