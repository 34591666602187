import React from "react"
import { Link as GatsbyLink } from "gatsby"
import parse from "html-react-parser"
import { Box, Grid, Typography, Link, Stack } from "@mui/material"
import { Button, colors, Paper, Textarea } from "@parallel-domain/pd-theme"

// import app components
import BackgroundImage from "../backgroundImage"

const PostCard = props => {
  const {
    index,
    uri,
    title,
    acf,
    featuredImage,
    imageAlignment = "left",
  } = props

  const isEven = index % 2 === 0

  return (
    <Paper sx={{ p: 0, overflow: "hidden" }}>
      <Grid
        container
        spacing={{ xs: 0, md: 8 }}
        sx={{ minHeight: { xs: "auto", md: "400px" } }}
      >
        <Grid item xs={12} md={6} sx={{ order: 2 }}>
          <Link to={uri} component={GatsbyLink} underline="none">
            <Box
              sx={{
                position: "relative",
                width: "100%",
                order: imageAlignment === "right" ? 3 : 1,
                height: { xs: "200px", md: "100%" },
              }}
            >
              {featuredImage && <BackgroundImage image={featuredImage.node} />}
            </Box>
          </Link>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            order: { xs: 3, md: isEven ? 1 : 3 },
          }}
        >
          <Stack
            spacing={2}
            sx={{
              py: { xs: 4, md: 10 },
              pl: { xs: 4, md: isEven ? 8 : 5 },
              pr: { xs: 4, md: isEven ? 5 : 8 },
            }}
          >
            <Link to={uri} component={GatsbyLink} underline="none">
              <Typography variant="h3" sx={{ color: colors.tertiary[700] }}>
                {parse(title)}
              </Typography>
            </Link>

            {acf?.excerpt && <Textarea p="body2">{acf.excerpt}</Textarea>}

            <Link to={uri} component={GatsbyLink} underline="none">
              <Button variant="outlined" color="primary">
                Read More
              </Button>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default PostCard
