import React from "react"
import GatsbyImage from "jam-cms/components/GatsbyImage"
import type { GatsbyImageProps } from "gatsby-plugin-image"
import { Box } from "@mui/material"

type Props = {
  image: GatsbyImageProps
  objectFit?: "cover" | "contain"
}

const BackgroundImage = (props: Props) => {
  const { image, objectFit = "cover", ...rest } = props

  return (
    <Box
      {...rest}
      sx={{
        zIndex: 0,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        overflow: "hidden",
        ".gatsby-image-wrapper": {
          width: "100%",
          height: "100%",
        },
      }}
    >
      {image && (
        <GatsbyImage
          image={image}
          style={{
            objectFit,
            objectPosition: "50% 50%",
            fontFamily: `object-fit: ${objectFit}; object-position: 50% 50%`,
          }}
          objectFit={objectFit}
          objectPosition="50% 50%"
          alt={image.alt}
        />
      )}
    </Box>
  )
}

export default BackgroundImage
