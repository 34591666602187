import React from "react"
import { Container, Stack } from "@mui/material"

// import app components
import PostCard from "../postCard"
import Pagination from "../pagination"

const PostGrid = props => {
  const { posts, page, postsPerPage } = props

  return (
    <Container maxWidth="lg" sx={{ py: 10 }}>
      <Stack spacing={6}>
        {posts &&
          posts
            .slice(
              (page - 1) * postsPerPage,
              (page - 1) * postsPerPage + postsPerPage
            )
            .map((post, index) => (
              <PostCard key={post.id} index={index} {...post} />
            ))}

        <Pagination {...props} />
      </Stack>
    </Container>
  )
}

export default PostGrid
