import React from "react"
import { Box } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

type Props = {
  backgroundcolor?: number
  backgroundpattern?: number
  margintop?: number
  marginbottom?: number
  paddingtop?: number
  paddingbottom?: number
  pattern?: string
  children: React.ReactNode
}

const backgroundColorMap = {
  0: "transparent",
  1: colors.secondary[600],
  2: colors.secondary[700],
  3: colors.secondary[800],
  4: colors.secondary[900],
}

const FlexWrapper = (props: Props) => {
  const data = useStaticQuery(graphql`
    query {
      linework1: file(relativePath: { eq: "linework-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            quality: 90
          )
        }
      }
      linework2: file(relativePath: { eq: "linework-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            quality: 90
          )
        }
      }
      linework3: file(relativePath: { eq: "linework-3.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            quality: 90
          )
        }
      }
      linework4: file(relativePath: { eq: "linework-4.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            placeholder: BLURRED
            quality: 90
          )
        }
      }
    }
  `)

  const getBackgroundImage = (pattern: number) => {
    if (pattern === 0) return null
    const imageMap = {
      1: data.linework1,
      2: data.linework2,
      3: data.linework3,
      4: data.linework4,
    }
    return getImage(imageMap[pattern])
  }

  const {
    backgroundcolor = 0,
    backgroundpattern = 0,
    margintop = 0,
    marginbottom = 0,
    paddingtop = 0,
    paddingbottom = 0,
    children,
  } = props

  const backgroundImage = getBackgroundImage(backgroundpattern)

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        mt: [margintop * 1, margintop * 3, margintop * 5],
        mb: [marginbottom * 1, marginbottom * 3, marginbottom * 5],
        pt: [paddingtop * 1, paddingtop * 3, paddingtop * 5],
        pb: [paddingbottom * 1, paddingbottom * 3, paddingbottom * 5],
        backgroundColor: backgroundColorMap[backgroundcolor],
      }}
    >
      {backgroundImage && (
        <GatsbyImage
          image={backgroundImage}
          alt="Lines"
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
            top: 0,
            pointerEvents: "none",
          }}
        />
      )}
      {children}
    </Box>
  )
}

export default FlexWrapper
