import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { Typography, Link, Box, Stack, IconButton } from "@mui/material"
import { colors } from "@parallel-domain/pd-theme"
import { Cancel } from "iconoir-react"

// import app components
import { Button } from "../../features/submodules/components/button"
import type { ButtonType } from "../../features/submodules/components/button/types"

type MenuItem = {
  key: number
  postID: number
  title: string
  url: string
  children: MenuItem[]
}

type Props = {
  items: MenuItem[]
  path: string
  open: boolean
  buttons: { button: ButtonType }[]
  onClose: () => void
}

const MobileMenu = (props: Props) => {
  const { path, items, open, buttons, onClose } = props

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 120,
        right: 0,
        top: 0,
        width: "100%",
        height: "100vh",
        transform: open ? "translateX(0)" : "translateX(100%)",
        transition: "ease all 0.2s",
        background: colors.secondary[900],
        pb: 4,
        overflow: "auto",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end", px: 2, pt: 2 }}>
        <IconButton aria-label="Close menu" onClick={onClose}>
          <Cancel style={{ color: colors.icons[100], fontSize: "1rem" }} />
        </IconButton>
      </Box>

      <Stack direction="column" spacing={1}>
        {items &&
          items.map(({ key, url, title, children }) => {
            return (
              <Box
                key={key}
                sx={{ textTransform: "uppercase", textAlign: "center" }}
              >
                {children.length ? (
                  <Box>
                    <Typography variant="subtitle1" sx={{ mb: 1 }}>
                      {title}
                    </Typography>

                    {children.map((o, i) => {
                      return (
                        <Link
                          key={i}
                          component={GatsbyLink}
                          to={o.url}
                          underline="none"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            p: 2,
                            color:
                              path === o.url ? colors.primary[900] : "inherit",
                            "&:hover": {
                              color: colors.primary[900],
                            },
                          }}
                        >
                          <Typography
                            component="span"
                            variant="caption"
                            color="inherit"
                          >
                            {o.title}
                          </Typography>
                        </Link>
                      )
                    })}
                  </Box>
                ) : (
                  <Link
                    component={GatsbyLink}
                    to={url}
                    underline="none"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      py: 2,
                      color: path === url ? colors.primary[900] : "inherit",
                      "&:hover": {
                        color: colors.primary[900],
                      },
                    }}
                  >
                    <Typography
                      component="span"
                      variant="caption"
                      color="inherit"
                    >
                      {title}
                    </Typography>
                  </Link>
                )}
              </Box>
            )
          })}
      </Stack>

      {buttons && (
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: "center", mt: 2 }}
        >
          {buttons.map((o: { button: ButtonType }, index: number) => (
            <Button
              key={index}
              size="small"
              {...o.button.link}
              variant={o.button.variant}
            />
          ))}
        </Stack>
      )}
    </Box>
  )
}

export default MobileMenu
