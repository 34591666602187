import React from "react"
import { graphql } from "gatsby"
import loadable from "@loadable/component"

// import app components
import PageWrapper from "../../../../components/pageWrapper"
import { FlexWrapper } from "../../../../features/submodules/components/flexWrapper"
import Seo from "../../../../components/seo"

const Banner = loadable(() => import("../../../../features/modules/components/banner"), {
  resolveComponent: (components) => components.Banner,
})

const Cards = loadable(() => import("../../../../features/modules/components/cards"), {
  resolveComponent: (components) => components.Cards,
})

const CardsHorizontal = loadable(() => import("../../../../features/modules/components/cardsHorizontal"), {
  resolveComponent: (components) => components.CardsHorizontal,
})

const Columns = loadable(() => import("../../../../features/modules/components/columns"), {
  resolveComponent: (components) => components.Columns,
})

const Form = loadable(() => import("../../../../features/modules/components/form"), {
  resolveComponent: (components) => components.Form,
})

const Hero = loadable(() => import("../../../../features/modules/components/hero"), {
  resolveComponent: (components) => components.Hero,
})

const Hexagon = loadable(() => import("../../../../features/modules/components/hexagon"), {
  resolveComponent: (components) => components.Hexagon,
})

const IconTextCentered = loadable(() => import("../../../../features/modules/components/iconTextCentered"), {
  resolveComponent: (components) => components.IconTextCentered,
})

const ImageCloud = loadable(() => import("../../../../features/modules/components/imageCloud"), {
  resolveComponent: (components) => components.ImageCloud,
})

const Logos = loadable(() => import("../../../../features/modules/components/logos"), {
  resolveComponent: (components) => components.Logos,
})

const Steps = loadable(() => import("../../../../features/modules/components/steps"), {
  resolveComponent: (components) => components.Steps,
})

const TeamMembers = loadable(() => import("../../../../features/modules/components/teamMembers"), {
  resolveComponent: (components) => components.TeamMembers,
})

const Teaser = loadable(() => import("../../../../features/modules/components/teaser"), {
  resolveComponent: (components) => components.Teaser,
})

const Testimonials = loadable(() => import("../../../../features/modules/components/testimonials"), {
  resolveComponent: (components) => components.Testimonials,
})

const TestimonialsWithImages = loadable(() => import("../../../../features/modules/components/testimonialsWithImages"), {
  resolveComponent: (components) => components.TestimonialsWithImages,
})

const TextImage = loadable(() => import("../../../../features/modules/components/textImage"), {
  resolveComponent: (components) => components.TextImage,
})

const TextImageColumns = loadable(() => import("../../../../features/modules/components/textImageColumns"), {
  resolveComponent: (components) => components.TextImageColumns,
})

const TextImageWithOffset = loadable(() => import("../../../../features/modules/components/textImageWithOffset"), {
  resolveComponent: (components) => components.TextImageWithOffset,
})

const Video = loadable(() => import("../../../../features/modules/components/video"), {
  resolveComponent: (components) => components.Video,
})

const VideoModal = loadable(() => import("../../../../features/modules/components/videoModal"), {
  resolveComponent: (components) => components.VideoModal,
})

const WordCloud = loadable(() => import("../../../../features/modules/components/wordCloud"), {
  resolveComponent: (components) => components.WordCloud,
})

const Text = loadable(() => import("../../../../features/modules/components/text"), {
  resolveComponent: (components) => components.Text,
})

const TextVideo = loadable(() => import("../../../../features/modules/components/textVideo"), {
  resolveComponent: (components) => components.TextVideo,
})

export const Head = props => <Seo {...props} />

// IMPORTANT: All keys (= component ids) MUST be lowercase
const components = {
  banner: Banner,
  hero: Hero,
  testimonials: Testimonials,
  textimagecolumns: TextImageColumns,
  teaser: Teaser,
  textimage: TextImage,
  columns: Columns,
  form: Form,
  logos: Logos,
  textimagewithoffset: TextImageWithOffset,
  icontextcentered: IconTextCentered,
  video: Video,
  videomodal: VideoModal,
  imagecloud: ImageCloud,
  teammembers: TeamMembers,
  cards: Cards,
  cardshorizontal: CardsHorizontal,
  hexagon: Hexagon,
  steps: Steps,
  testimonialswithimages: TestimonialsWithImages,
  wordcloud: WordCloud,
  text: Text,
  textvideo: TextVideo,
}

const Template = props => {
  const {
    pageContext: { themeOptions },
    data: {
      wpPage: {
        template: { acf },
      },
    },
  } = props

  return (
    <PageWrapper {...props}>
      {acf?.flex &&
        acf.flex.map(
          ({ id: fieldId, fieldGroupName, wrapper, ...rest }, index) => {
            const id = fieldId || fieldGroupName?.split("_").pop().toLowerCase()

            const Component = components?.[id]

            return (
              Component && (
                <FlexWrapper key={index} {...wrapper}>
                  <Component {...rest} themeOptions={themeOptions} />
                </FlexWrapper>
              )
            )
          }
        )}
    </PageWrapper>
  )
}

export const Query = graphql`
  query PageDefault($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      databaseId
      title
      template {
        ... on WpDefaultTemplate {
          templateName
          acf {
            flex {
              ... on WpDefaultTemplate_Acf_Flex_Textvideo {
                fieldGroupName
                icon {
                  altText
                  gatsbyImage(width: 400)
                  svg
                }
                title {
                  color
                  fieldGroupName
                  headline
                  variant
                }
                text
                video {
                  host
                  id
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Banner {
                fieldGroupName
                headline
                buttons {
                  button {
                    fieldGroupName
                    link {
                      target
                      title
                      url
                    }
                    variant
                  }
                  fieldGroupName
                }
                image {
                  desktop {
                    altText
                    gatsbyImage(width: 1920)
                    svg
                  }
                  mobile {
                    altText
                    gatsbyImage(width: 600)
                    svg
                  }
                }
                subline
                text
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Hero {
                fieldGroupName
                headline
                buttons {
                  link {
                    target
                    title
                    url
                  }
                  fieldGroupName
                  variant
                }
                subline
                video {
                  host
                  id
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Testimonials {
                fieldGroupName
                items {
                  author
                  company
                  text
                  fieldGroupName
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Textimagecolumns {
                columnsmobile
                columnsdesktop
                fieldGroupName
                headline
                textstyle
                image {
                  desktop {
                    altText
                    gatsbyImage(width: 1920)
                    svg
                  }
                  mobile {
                    altText
                    gatsbyImage(width: 600)
                    svg
                  }
                }
                items {
                  fieldGroupName
                  icon {
                    altText
                    gatsbyImage(width: 400)
                    svg
                  }
                  text
                  title {
                    color
                    fieldGroupName
                    headline
                    variant
                  }
                }
                subline
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Teaser {
                text
                fieldGroupName
                buttons {
                  fieldGroupName
                  link {
                    target
                    title
                    url
                  }
                  variant
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Textimage {
                fieldGroupName
                headline
                buttons {
                  button {
                    fieldGroupName
                    link {
                      target
                      title
                      url
                    }
                    variant
                  }
                  fieldGroupName
                }
                image {
                  desktop {
                    altText
                    gatsbyImage(width: 1920)
                    svg
                  }
                  mobile {
                    altText
                    gatsbyImage(width: 600)
                    svg
                  }
                }
                imagealignment
                subline
                text
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  paddingbottom
                  margintop
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Columns {
                columnsdesktop
                columnsmobile
                fieldGroupName
                headline
                items {
                  fieldGroupName
                  headline
                  image {
                    desktop {
                      altText
                      gatsbyImage(width: 600)
                      svg
                    }
                    mobile {
                      altText
                      gatsbyImage(width: 400)
                      svg
                    }
                  }
                  text
                }
                subline
                text
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Form {
                buttontext
                fieldGroupName
                form
                headline
                text
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Logos {
                columnsdesktop
                columnsmobile
                fieldGroupName
                text
                items {
                  fieldGroupName
                  image {
                    desktop {
                      altText
                      gatsbyImage(width: 400)
                      svg
                    }
                    mobile {
                      altText
                      gatsbyImage(width: 400)
                      svg
                    }
                  }
                }
                title {
                  color
                  fieldGroupName
                  headline
                  variant
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Textimagewithoffset {
                fieldGroupName
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingtop
                  paddingbottom
                }
                items {
                  fieldGroupName
                  image {
                    desktop {
                      altText
                      gatsbyImage(width: 800)
                      svg
                    }
                    mobile {
                      altText
                      gatsbyImage(width: 400)
                      svg
                    }
                  }
                  text
                  title {
                    color
                    fieldGroupName
                    headline
                    variant
                  }
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Icontextcentered {
                fieldGroupName
                text
                icon {
                  altText
                  gatsbyImage(width: 400)
                  svg
                }
                title {
                  color
                  fieldGroupName
                  headline
                  variant
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Imagecloud {
                fieldGroupName
                images {
                  desktop {
                    altText
                    gatsbyImage(width: 1920)
                    svg
                  }
                  mobile {
                    altText
                    gatsbyImage(width: 600)
                    svg
                  }
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Videomodal {
                fieldGroupName
                image {
                  desktop {
                    altText
                    gatsbyImage(width: 1920)
                    svg
                  }
                  mobile {
                    altText
                    gatsbyImage(width: 600)
                    svg
                  }
                }
                video {
                  fieldGroupName
                  host
                  id
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Video {
                fieldGroupName
                video {
                  fieldGroupName
                  host
                  id
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Wordcloud {
                fieldGroupName
                items {
                  fieldGroupName
                  word
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Testimonialswithimages {
                fieldGroupName
                items {
                  author
                  fieldGroupName
                  image {
                    desktop {
                      altText
                      gatsbyImage(width: 300)
                      svg
                    }
                    fieldGroupName
                    mobile {
                      altText
                      gatsbyImage(width: 300)
                      svg
                    }
                  }
                  subline
                  text
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Steps {
                fieldGroupName
                headline
                items {
                  fieldGroupName
                  steptitle
                  text
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Hexagon {
                fieldGroupName
                items {
                  fieldGroupName
                  headline
                  subline
                  text
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Cardshorizontal {
                fieldGroupName
                items {
                  fieldGroupName
                  headline
                  image {
                    desktop {
                      altText
                      gatsbyImage(width: 900)
                      svg
                    }
                    fieldGroupName
                    mobile {
                      altText
                      gatsbyImage(width: 900)
                      svg
                    }
                  }
                  subline
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Cards {
                fieldGroupName
                items {
                  button {
                    fieldGroupName
                    link {
                      target
                      title
                      url
                    }
                    variant
                  }
                  cardtitle
                  fieldGroupName
                  image {
                    desktop {
                      altText
                      gatsbyImage(width: 600)
                      svg
                    }
                    fieldGroupName
                    mobile {
                      altText
                      gatsbyImage(width: 400)
                      svg
                    }
                  }
                  text
                }
                title {
                  headline
                  fieldGroupName
                  color
                  variant
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Teammembers {
                fieldGroupName
                items {
                  fieldGroupName
                  image {
                    desktop {
                      altText
                      gatsbyImage(width: 600)
                      svg
                    }
                    fieldGroupName
                    mobile {
                      altText
                      gatsbyImage(width: 400)
                      svg
                    }
                  }
                  text
                }
                title {
                  color
                  fieldGroupName
                  headline
                  variant
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  marginbottom
                  margintop
                  paddingbottom
                  paddingtop
                }
              }
              ... on WpDefaultTemplate_Acf_Flex_Text {
                fieldGroupName
                text
                title {
                  color
                  fieldGroupName
                  headline
                  variant
                }
                wrapper {
                  backgroundcolor
                  backgroundpattern
                  fieldGroupName
                  margintop
                  paddingbottom
                  paddingtop
                  marginbottom
                }
              }
            }
            fieldGroupName
          }
        }
      }
    }
  }
`

export default Template
