import React from "react"
import { Box, Typography, Container, Stack } from "@mui/material"
import parse from "html-react-parser"
import { Textarea } from "@parallel-domain/pd-theme"

// import app components
import { Image, ImageType } from "../../../submodules/components/image"
import { Button, ButtonType } from "../../../submodules/components/button"

type Props = {
  subline: string
  headline: string
  text: string
  buttons: { button: ButtonType }[]
  image: ImageType
  position: string
}

const Banner = (props: Props) => {
  const {
    subline,
    headline,
    text,
    buttons,
    image,
    position = "initial",
  } = props

  return (
    <Box
      sx={{
        py: [3, 4, 5],
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        position,
      }}
    >
      {image && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          <Image
            {...image}
            sx={{
              height: "100%",
              ".gatsby-image-wrapper": {
                height: "100%",
                width: "100%",
              },
            }}
          />
        </Box>
      )}

      <Container maxWidth="lg" sx={{ position: "relative", zIndex: 10 }}>
        <Stack spacing={2} sx={{ textAlign: "center" }}>
          {subline && (
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Typography variant="subtitle2" color="primary" component="div">
                {parse(subline)}
              </Typography>
            </Box>
          )}

          {headline && (
            <Typography variant="h1" component="h2">
              {parse(headline)}
            </Typography>
          )}

          {text && (
            <div>
              <Container maxWidth="md">
                <Textarea>{text}</Textarea>
              </Container>
            </div>
          )}

          {buttons && buttons.length > 0 && (
            <Stack
              direction="row"
              spacing={2}
              sx={{ justifyContent: "center" }}
            >
              {buttons.map((o, index: number) => (
                <Button
                  key={index}
                  {...o.button.link}
                  variant={o.button.variant}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default Banner
