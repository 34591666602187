import flexWrapperConfig from "../../../submodules/components/flexWrapper/config"

const config = {
  id: "form",
  label: "Form",
  fields: [
    {
      id: "headline",
      type: "text",
      label: "Headline",
    },
    {
      id: "text",
      type: "wysiwyg",
      label: "Text",
    },
    {
      id: "form",
      type: "select",
      label: "Form",
      defaultValue: "contact",
      options: [
        {
          name: "Contact",
          value: "contact",
        },
        {
          name: "Demo",
          value: "contact2",
        },
        {
          name: "Waitlist",
          value: "waitlist",
        },
        {
          name: "Newsletter",
          value: "newsletter",
        },
        {
          name: "CES",
          value: "ces",
        },
      ],
    },
    {
      id: "buttontext",
      type: "text",
      label: "Button Text",
    },
    ...flexWrapperConfig,
  ],
}

export default config
