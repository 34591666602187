import React from "react"
import WrapPage from "./gatsby-utils/wrap-page"
import { loadableReady } from "@loadable/component"

export const wrapPageElement = props => <WrapPage {...props} />

export const onClientEntry = () => {
  loadableReady(() => {
    // Ready to go
  }).catch(error => {
    console.error("loadableReady error:", error)
  })
}
