import React from "react"
import { Box, Typography, Container, Stack } from "@mui/material"
import parse from "html-react-parser"
import { Textarea } from "@parallel-domain/pd-theme"

// import app components
import ContactForm from "./ContactForm"
import DemoForm from "./DemoForm"
import NewsletterForm from "./NewsletterForm"
import WaitlistForm from "./Waitlist"
import CesForm from "./CesForm"

const Form = props => {
  const {
    headline,
    text,
    buttontext = "Submit",
    form,
    disableGutters = false,
  } = props

  const renderForm = () => {
    switch (form) {
      case "contact":
        return <ContactForm buttonText={buttontext} />

      case "contact2":
        return <DemoForm buttonText={buttontext} />

      case "waitlist":
        return <WaitlistForm buttonText={buttontext} />

      case "newsletter":
        return <NewsletterForm buttonText={buttontext} />

      case "ces":
        return <CesForm buttonText={buttontext} />
    }
  }

  return (
    <Box sx={{ py: [3, 4, 5] }}>
      <Container
        maxWidth={form === "newsletter" ? "sm" : "md"}
        disableGutters={disableGutters}
      >
        {(headline || text) && (
          <Stack
            spacing={2}
            sx={{ mb: { xs: 2, sm: 6 }, alignItems: "center" }}
          >
            {headline && (
              <Typography variant="h2">{parse(headline)}</Typography>
            )}

            {text && <Textarea>{text}</Textarea>}
          </Stack>
        )}

        {renderForm()}
      </Container>
    </Box>
  )
}

export default Form
