import React from "react"
import { Box, Typography, IconButton } from "@mui/material"
import Slider from "react-slick"
import { Textarea, colors } from "@parallel-domain/pd-theme"
import { ArrowRightCircled, ArrowLeftCircled } from "iconoir-react"

const ArrowWrapper = ({ currentSlide, slideCount, children, ...rest }) => (
  <span {...rest}>{children}</span>
)

const settings = {
  dots: false,
  arrows: true,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: (
    <ArrowWrapper>
      <IconButton aria-label="Next slide" sx={{p: 0}}>
        <ArrowRightCircled
          style={{ fontSize: "1.5rem", color: colors.primary[900] }}
        />
      </IconButton>
    </ArrowWrapper>
  ),
  prevArrow: (
    <ArrowWrapper>
      <IconButton aria-label="Previous slide" sx={{p: 0}}>
        <ArrowLeftCircled
          style={{ fontSize: "1.5rem", color: colors.primary[900] }}
        />
      </IconButton>
    </ArrowWrapper>
  ),
  responsive: [
    {
      breakpoint: 600,
      settings: {
        arrows: true,
      },
    },
  ],
}

type Props = {
  items: { text: string; author: string; company: string }[]
}

const Testimonials = (props: Props) => {
  const { items } = props

  return (
    <Box sx={{ py: 8, overflow: "hidden" }}>
      {items && (
        <Box
          sx={{
            position: "relative",
            "*": { outline: "none" },
            ".slick-track": { display: "flex !important" },
            ".slick-list": { margin: "0 -10px" },
            ".slick-slide": {
              position: "relative",
              height: "inherit !important",
              borderRadius: "10px",
              margin: "0 10px",
            },
            ".slick-arrow": {
              position: "absolute",
              display: "flex !important",
              width: "36px",
              height: "36px",
              transition: "ease all 0.2s",
              zIndex: 10,
              opacity: 0.6,
            },
            "&:hover": {
              ".slick-arrow": { opacity: 0.6, "&:hover": { opacity: 1 } },
            },
            ".slick-prev": {
              left: "100px",
              top: "50%",
              transform: "translateY(-50%)",
            },
            ".slick-next": {
              right: "100px",
              top: "50%",
              transform: "translateY(-50%)",
            },
            "@media (max-width: 1100px)": {
              ".slick-arrow": {
                top: "auto",
                bottom: "-62px",
                transform: "none",
              },
              ".slick-prev": {
                left: "calc(50% - 50px)",
              },
              ".slick-next": {
                right: "calc(50% - 50px)",
              },
            },
          }}
        >
          <Slider {...settings}>
            {items.map((o, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    textAlign: "center",
                    px: 2,
                  }}
                >
                  <Box sx={{ maxWidth: "800px", width: "100%", mx: "auto" }}>
                    {o?.text && <Textarea p="h4">{o.text}</Textarea>}

                    {o?.company && (
                      <Typography color="primary">{o.company}</Typography>
                    )}

                    {o?.author && (
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        component="div"
                      >
                        {o.author}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )
            })}
          </Slider>
        </Box>
      )}
    </Box>
  )
}

export default Testimonials
